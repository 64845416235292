import React from "react"
import Layout from "../components/Layout"
import styled from "styled-components"
import SEO from "../components/SEO"

const Block = styled.div`
  margin: 0 auto;
  padding: 32px 0;
  max-width: 800px;
  a {
    font-weight: 400;
    text-decoration: underline;
  }
  p {
    font-size: 1rem;
  }
`

const Title = styled.h2`
  font-size: 3rem;
  margin: 62px 0 12px;
`

const EndTitle = styled.h5`
  margin-top: 12px;
  font-size: 1.2rem;
`

function AboutPage() {
  return (
    <React.Fragment>
      <SEO title={"Om os"} description={"Læs om Lineout"} />
      <Layout>
        <Block>
          <Title>Dit online outlet</Title>
          <p>
            Kender du det, når man står og er på udkig efter noget tøj? Det gør
            du formentligt. Hvor skal man lede? Der er jo så mange onlineshops.
            Men hvilken shop har præcis det du leder efter? Er der et tilbud hos
            en af disse shops, hvor du vil spare en masse penge? I hvertfald, så
            er du tvunget til at lede frem og tilbage på diverse shops. <br />
            <b>Det skal være slut.</b>
            <br />
            For vi samler nemlig disse onlineshops, og gør det markant nemmere
            for dig, at have overblikket over det tøj, der kan købes lige nu
            samt hvilke tilbud, der er at finde.
            <br />
            <EndTitle>
              Vi håber på at vi kan hjælpe dig, med at finde lige det du søger 👌
            </EndTitle>
          </p>
        </Block>
      </Layout>
    </React.Fragment>
  )
}

export default AboutPage
